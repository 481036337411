import {AbpHttpConfigurationService, AbpHttpInterceptor, RefreshTokenService} from 'abp-ng2-module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import {ZeroRefreshTokenService} from '@account/auth/zero-refresh-token.service';
import {ZeroTemplateHttpConfigurationService} from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.JobAssignServiceProxy,        
        ApiServiceProxies.BannersServiceProxy,
        ApiServiceProxies.FinancialYearSelectsServiceProxy,
        ApiServiceProxies.ConfirmationReportServiceProxy,
        ApiServiceProxies.CrmDealsServiceProxy,
        ApiServiceProxies.CrmLeadsServiceProxy,
        ApiServiceProxies.SalesForTicketServiceProxy,
        ApiServiceProxies.SalesQuotationReportServiceProxy,
        ApiServiceProxies.BarCodeServiceProxy,
        ApiServiceProxies.PdcCleranceReportServiceProxy,
        ApiServiceProxies.PdcPayableReportServiceProxy,
        ApiServiceProxies.CRMProductServiceProxy,
        ApiServiceProxies.WorkUnitsServiceProxy,
        ApiServiceProxies.TechniciansServiceProxy,
        ApiServiceProxies.PaySlipsServiceProxy,
        ApiServiceProxies.CrmTicketsServiceProxy,
        ApiServiceProxies.ServicePendingReportServiceProxy,
        ApiServiceProxies.ServicePendingReportNewServiceProxy,
        ApiServiceProxies.TodayReceiptAndPaymentReportServiceProxy,
        ApiServiceProxies.ProductMovementAnalysisReportServiceProxy,
        ApiServiceProxies.MovementAnalysisReportServiceProxy,
        ApiServiceProxies.ProductProfitReportServiceProxy,
        ApiServiceProxies.CustomersServiceProxy,
        ApiServiceProxies.StockJournalReportServiceProxy,
        ApiServiceProxies.CashFlowReportServiceProxy,
        ApiServiceProxies.PdcReceivableReportServiceProxy,
        ApiServiceProxies.MonthlyReportServiceProxy,
        ApiServiceProxies.StockRequestReportServiceProxy,
        ApiServiceProxies.StockReceiptReportServiceProxy,
        ApiServiceProxies.BalanceSheetReportServiceProxy,
        ApiServiceProxies.PurchaseAdditionalCostServiceProxy,
        ApiServiceProxies.CrmCallServiceProxy,
        ApiServiceProxies.PoSalesServiceProxy,
        ApiServiceProxies.CalenderEventsServiceProxy,
        ApiServiceProxies.CrmMeetingServiceProxy,
        ApiServiceProxies.CrmTaskServiceProxy,
        ApiServiceProxies.TicketMastersServiceProxy,
        ApiServiceProxies.StockTransferReportServiceProxy,
        ApiServiceProxies.CrmContactsServiceProxy,
        ApiServiceProxies.JournalMastersServiceProxy,
        ApiServiceProxies.EmployeeOfficeTimesServiceProxy,
        ApiServiceProxies.EmployeeOverTimesServiceProxy,
        ApiServiceProxies.AgeingReportNewServiceProxy,
        ApiServiceProxies.ProfitLossReportServiceProxy,
        ApiServiceProxies.ProductWiseReportServiceProxy,
        ApiServiceProxies.EmployeeBonusDeductionsServiceProxy,
        ApiServiceProxies.StockJournalMastersServiceProxy,
        ApiServiceProxies.DeliveryNoteReportServiceProxy,
        ApiServiceProxies.ReportingServiceProxy,
        ApiServiceProxies.AccountGroupReportServiceProxy,
        ApiServiceProxies.AccountLedgerReportServiceProxy,
        ApiServiceProxies.DayBookReportServiceProxy,
        ApiServiceProxies.CashBookReportServiceProxy,
        ApiServiceProxies.StockReportServiceProxy,
        ApiServiceProxies.StockReceiptServiceProxy,
        ApiServiceProxies.ConsumerTypesServiceProxy,
        ApiServiceProxies.StockTransferServiceProxy,
        ApiServiceProxies.ProductWiseProfitReportServiceProxy,
        ApiServiceProxies.ProductMovementReportServiceProxy,
        ApiServiceProxies.ProductStatisticsReportServiceProxy,
        ApiServiceProxies.OutstandingReportServiceProxy,
        ApiServiceProxies.DamagedStockReportServiceProxy,
        ApiServiceProxies.AgeingReportServiceProxy,
        ApiServiceProxies.DailyCollectionReportServiceProxy,
        ApiServiceProxies.MaterialSalesReportServiceProxy,
        ApiServiceProxies.ImeiTrackerReportServiceProxy,
        ApiServiceProxies.ImeiAvailableReportServiceProxy,
        ApiServiceProxies.TrailBalanceReportServiceProxy,
        ApiServiceProxies.ServiceDeliveryReportServiceProxy,
        ApiServiceProxies.PricingLevelsServiceProxy,
        ApiServiceProxies.PricingLevelIncludedReportServiceProxy,
        ApiServiceProxies.PriceListsServiceProxy,
        ApiServiceProxies.CrmCallServiceProxy,
        ApiServiceProxies.MergeLedgerServiceProxy,
        ApiServiceProxies.PartywiseTaxReportServiceProxy,

        // date
        ApiServiceProxies.DateSelectsServiceProxy,
        ApiServiceProxies.DashboardRealDataGeneratorServiceProxy,

        ApiServiceProxies.EmployeeLoanAndAdvancesServiceProxy,
        ApiServiceProxies.SalaryVoucherMastersServiceProxy,
        ApiServiceProxies.EmiServiceProxy,
        ApiServiceProxies.OdServiceProxy,
        ApiServiceProxies.ServiceDeliveriesServiceProxy,
        ApiServiceProxies.SalesForTicketServiceProxy,
        ApiServiceProxies.WarrantyTransfersServiceProxy,
        ApiServiceProxies.LcServiceProxy,
        ApiServiceProxies.InterestCalculationServiceProxy,
        ApiServiceProxies.SalesReportServiceProxy,
        ApiServiceProxies.SalesReturnReportServiceProxy,
        ApiServiceProxies.ServiceReceiptServiceProxy,
        ApiServiceProxies.AccountWiseLedgerReportServiceProxy,
        ApiServiceProxies.EmployeeLoanAndAdvancesServiceProxy,
        ApiServiceProxies.EmployeeSalariesServiceProxy,
        ApiServiceProxies.EmployeeLopDeductionsServiceProxy,
        ApiServiceProxies.StlServiceProxy,
        ApiServiceProxies.ImeiReportServiceProxy,
        ApiServiceProxies.PurchaseReportServiceProxy,
        ApiServiceProxies.PaymentVoucherReportServiceProxy,
        ApiServiceProxies.PurchaseReturnReportServiceProxy,
        ApiServiceProxies.PartyWiseReportServiceProxy,
        ApiServiceProxies.SoftwareReportServiceProxy,
        ApiServiceProxies.MaterialReceiptReportServiceProxy,
        ApiServiceProxies.RejectionOutMasterReportServiceProxy,
        ApiServiceProxies.PurchaseOrderMasterReportServiceProxy,
        ApiServiceProxies.MonthlySalesReportServiceProxy,
        ApiServiceProxies.MonthlyPurchaseReportServiceProxy,
        ApiServiceProxies.LedgerSummaryReportServiceProxy,
        ApiServiceProxies.TaxReportServiceProxy,
        ApiServiceProxies.ImeiTrackingReportServiceProxy,
        ApiServiceProxies.VatSummaryReportServiceProxy,
        ApiServiceProxies.DashboardReportServiceProxy,
        ApiServiceProxies.ImportTaxReportServiceProxy,
        ApiServiceProxies.StatusWiseReportServiceProxy,
        // transactions
        ApiServiceProxies.BankReconciliationsServiceProxy,
        ApiServiceProxies.PdcClearancesServiceProxy,
        ApiServiceProxies.PdcPayablesServiceProxy,
        ApiServiceProxies.PdcReceivablesServiceProxy,
        ApiServiceProxies.PhysicalStockMastersServiceProxy,
        ApiServiceProxies.StockRequestMastersServiceProxy,
        ApiServiceProxies.LoanReceivedServiceProxy,
        ApiServiceProxies.WarrantyReceiptsServiceProxy,
        ApiServiceProxies.SalesQuotationMastersServiceProxy,
        ApiServiceProxies.SalesOrderMastersServiceProxy,
        ApiServiceProxies.RejectionInMastersServiceProxy,
        ApiServiceProxies.RejectionOutMastersServiceProxy,
        ApiServiceProxies.SalesMastersServiceProxy,
        ApiServiceProxies.SalesReturnMastersServiceProxy,
        ApiServiceProxies.DeliveryNoteMastersServiceProxy,
        ApiServiceProxies.PayHeadsServiceProxy,
        ApiServiceProxies.AttendanceMastersServiceProxy,
        ApiServiceProxies.TaxesServiceProxy,
        ApiServiceProxies.TaxableCustomerServiceProxy,
        ApiServiceProxies.VoucherTypesServiceProxy,
        ApiServiceProxies.PurchaseReturnsServiceProxy,
        ApiServiceProxies.PurchaseMastersServiceProxy,
        ApiServiceProxies.ModelNosServiceProxy,
        ApiServiceProxies.PurchaseOrderMastersServiceProxy,
        ApiServiceProxies.MaterialReceiptMastersServiceProxy,
        ApiServiceProxies.ProductServiceProxy,
        ApiServiceProxies.ContraMastersServiceProxy,
        ApiServiceProxies.ProductGroupsServiceProxy,
        ApiServiceProxies.RacksServiceProxy,
        ApiServiceProxies.GodownsServiceProxy,
        ApiServiceProxies.SizesServiceProxy,
        ApiServiceProxies.BrandsServiceProxy,
        ApiServiceProxies.UnitsServiceProxy,
        ApiServiceProxies.ReceiptMastersServiceProxy,
        ApiServiceProxies.PaymentMastersServiceProxy,
        ApiServiceProxies.FinancialYearsServiceProxy,
        ApiServiceProxies.EmployeesServiceProxy,
        ApiServiceProxies.AccountLedgersServiceProxy,
        ApiServiceProxies.AccountGroupsServiceProxy,
        ApiServiceProxies.BranchsServiceProxy,
        ApiServiceProxies.DepartmentsServiceProxy,
        ApiServiceProxies.DesignationsServiceProxy,
        ApiServiceProxies.ServiceOrdersServiceProxy,
        ApiServiceProxies.RejectionInReportServiceProxy,
        ApiServiceProxies.UnPurchasedReportServiceProxy,
        ApiServiceProxies.UnSoldReportServiceProxy,

        // ASP.NET Zero helpers
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,

        // ASP.NET Zero helpers

        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        {provide: RefreshTokenService, useClass: ZeroRefreshTokenService},
        {provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService},
        {provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true},
    ],
})
export class ServiceProxyModule {
}
