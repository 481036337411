<div class="app-toolbar py-3 py-lg-2 color-gray-600" id="kt_app_toolbar">
    <!--begin::Toolbar container-->
    <div *ngIf="isSimpleHeader; else simpleHead" class="app-container container-fluid d-flex flex-stack"
         id="kt_app_toolbar_container">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 mb-lg-0"
             data-kt-swapper="true"
             data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
             data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_toolbar_container'}">
            <!--begin::Title-->
            <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center text-capitalize my-0">{{ title }}</h1>
            <!--end::Title-->
        </div>
        <!--end::Page title-->

        <div class="position-relative w-md-400px me-md-2">
            <i class="fa-duotone fa-magnifying-glass fs-3 text-gray-500 position-absolute top-50 translate-middle ms-6"><span
                    class="path1"></span><span class="path2"></span></i>
            <input (keyup)="searchFilter($event.target.value)" (keyup.enter)="searchFilerOnEnter($event.target.value)"
                   [(ngModel)]="filterText" [ngModelOptions]="{ standalone: true }"
                   class="form-control form-control-solid ps-10" name="search" placeholder="Search"
                   type="text"
                   value="">
        </div>

        <!--begin::Action group-->
        <div class="d-flex align-items-center gap-2">
            <!--begin::Action wrapper-->
            <div class="d-flex align-items-center">


                <!--begin::NoUiSlider-->
                <div class="d-flex align-items-center ps-4">

                    <a (click)="routeToAdd()" *ngIf="isAdd" class="btn btn-sm btn-flex btn-primary fw-bold">
                        <i class="fa-duotone fa-circle-plus fs-6 text-light me-1 ">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                        </i>
                        Add
                    </a>

                    <a (click)="showCards()" *ngIf="showCard && isDesktop"
                       class="btn btn-sm btn-flex btn-primary fw-bold">
                        <i class="fa-duotone fa-circle-plus fs-6 text-light me-1 ">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                        </i>
                        Add
                    </a>

                    <a (click)="routeToAddMultiple()" *ngIf="isMultiple && isDesktop"
                       class="btn btn-sm btn-flex btn-primary fw-bold">
                        <i class="fa-duotone fa-circle-plus fs-6 text-light me-1 ">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                        </i>
                        Multiple
                    </a>

                    <!--end::NoUiSlider-->


                </div>
                <!--end::Action wrapper-->

                <!--begin::Action wrapper-->
                <div class="d-flex align-items-center">

                    <!--begin::Actions-->
                    <div class="d-flex">

                        <a *ngIf="export||import"
                           class="dropdown btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
                           data-kt-menu-placement="bottom-end" data-kt-menu-trigger="menu">
                            <i class="fa-duotone fa-download fs-6 text-muted ">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                            <div class="dropdown-content">

                                <div class="menu-item">
                                    <!--begin:Menu link-->
                                    <a *ngIf="export" (click)="exportToExcel($event)">
                                        <i class="fa-duotone fa-file-xls"></i> <span>Export To Excel</span>
                                    </a><!--end:Menu link-->
                                </div>

                                <div class="menu-item">
                                    <!--begin:Menu link-->
                                    
                                    <a *ngIf="import" href="javascript:">
                                        <i class="fa-duotone fa-file-spreadsheet"></i>
                                        <p-fileUpload
                                            #ExcelFileUpload
                                            (onError)="onUploadExcelError()"
                                            (uploadHandler)="importToExcelOperations($event)"
                                            accept=".csv,.xls,.xlsx"
                                            auto="auto"
                                            chooseLabel="{{ 'Import' | localize }}"
                                            customUpload="true"
                                            maxFileSize="10000000"
                                            name="ExcelFileUpload"
                                        >
                                        </p-fileUpload>
                                    </a>
                                    <!--end:Menu link-->
                                </div>
                            </div>
                        </a>

                        <a (click)="openFilter()" *ngIf="!advancedFiltersAreShown && filter" class="cursor-pointer">
                            <i class="fa-duotone fa-down  fs-5 text-muted">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>

                        <a (click)="closeFilter()" *ngIf="advancedFiltersAreShown && filter" class="cursor-pointer">
                            <i class="fa-duotone fa-up  fs-5 text-muted">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>

                        <!--begin::Action-->
                    </div>
                    <!--end::Actions-->
                </div>
                <!--end::Action wrapper-->
            </div>
            <!--end::Action group-->
        </div>
        <!--end::Toolbar container-->
    </div>

    <ng-template #simpleHead>
        <!-- new added code responsive header -->

        <div [hidden]="isSimpleHeader" class="app-container container-fluid d-flex flex-stack"
             id="kt_app_toolbar_container">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 mb-lg-0"
                 data-kt-swapper="true"
                 data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}"
                 data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_toolbar_container'}">
                <!--begin::Title-->
                <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center text-capitalize my-0">  {{ description }}</h1>
                <!--end::Title-->
            </div>
            <!--end::Page title-->

            <div class="d-flex align-items-center gap-2">
                <!--begin::Action wrapper-->
                <div class="d-flex align-items-center">


                    <!--begin::NoUiSlider-->
                    <div class="d-flex align-items-center ps-4">
                        <div>
                            <ng-content select="div[role=actions]"></ng-content>
                        </div>

                    </div>

                </div>

            </div>
            <!--end::Toolbar container-->
        </div>
    </ng-template>

    <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>